import "./homepage.scss";

import Inside from "../../components/Inside/Inside";
import OurServices from "../../components/OurServices/OurServices";
import Privilege from "../../components/Privilege/Privilege";
import Slider from "../../components/Slider/Slider";
import GoToTop from "../../helpers/GoToTop";
import Seo from "../../helpers/Seo";

function HomePage() {
  return (
    <div className="homepage">
      <Seo
        title="Allobank | All for One, One for All"
        description="Allo Bank memberikan kemudahan menabung dan bertransaksi langsung lewat ponsel kamu! Solusi finansial untuk kamu yang aktif. "
        url={window.location.href}
      />
      <GoToTop />
      <Slider />
      <Inside />
      <OurServices />
      <Privilege />
    </div>
  );
}

export default HomePage;
