import "./cardpromotionlit.scss";
import { useNavigate } from "react-router-dom";

function CardPromotionLit({ dataPromo }) {
  const navigate = useNavigate();
  return (
    <div className="cardpromotionlit">
      <div className="image__wrapper">
        <img src={dataPromo.image} alt="" />
      </div>
      <div className="cardpromotion__details">
        <div className="expired__date">
          Expired, <span>{dataPromo.expired}</span>{" "}
        </div>
        <div
          className="cardpromotion__title"
          onClick={() => navigate(`/promo/${dataPromo.slug}`)}
        >
          {dataPromo.titleCard}
        </div>
        <div className="cardpromotion__desc">{dataPromo.descriptionCard}</div>
      </div>
    </div>
  );
}

export default CardPromotionLit;
