import { useSelector } from "react-redux";
import "./comingsoon.scss";

function ComingSoon({ type }) {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      heading: "Launching Soon",
      descDownload: "We are preparing our mobile app at the moment.",
      descPromo: "We are preparing special promotions for you.",
      descNews: "We are preparing news & events for you.",
      descCsr: "We are preparing CSR for you.",
      notFound: "The page you are looking for was not found!",
    },
    Indonesia: {
      heading: "Segera Hadir",
      descDownload: "Aplikasi kami masih dalam tahap pengembangan",
      descPromo: "Nantikan promo terbaru dari kami",
      descNews: "Nantikan berita dan event terbaru dari kami",
      descCsr: "Nantikan berita CSR terbaru dari kami",
      notFound: "Halaman yang Anda cari tidak ditemukan!",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  let subHeading = "";
  if (type === "download-app") {
    subHeading = content.descDownload;
  } else if (type === "promo") {
    subHeading = content.descPromo;
  } else if (type === "news") {
    subHeading = content.descNews;
  } else if (type === "csr") {
    subHeading = content.descCsr;
  } else {
    subHeading = content.notFound;
  }

  return (
    <div className="comingsoon">
      <div className="comingsoon__container">
        <div className="decoration">
          <img src="/assets/supergraphics background.png" alt="" />
        </div>
        <div className="contents">
          <div className="wrapper__images">
            <img src="/assets/LAUNCHING SOON.png" alt="" />
          </div>
          {type !== "not-found" && (
            <div className="heading">{content.heading}</div>
          )}
          <div className="sub__heading">
            {subHeading}
            {type !== "not-found" && <p>Stay tuned!</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
