import { useSelector } from "react-redux";
import "./corporate-governance.scss";

import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";
import Seo from "../../helpers/Seo";
import HeroImage from "../../components/HeroImage/HeroImage";
import FormCorporateGovernance from "../../components/FormCorporateGovernance/FormCorporateGovernance";
import GoToTop from "../../helpers/GoToTop";
// import { dataManagementCommitte } from "../../data/dataManagementCommitte";

function CorporateGovernance() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/page/whistleblowing");
  // const { response, loading } = useFetch("http://localhost:3001/corporate");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  // console.log("content.data :>> ", content.data);

  return (
    <div className="managementcommitte">
      <Seo
        title="Tata Kelola Perusahaan | Allobank"
        description="Allo Bank berkomitmen untuk menjaga integritas dan kepercayaan yang diberikan oleh Nasabah dalam mengelola perusahaan."
        url={`www.allobank.com/about-us/corporate-governance}`}
      />
      <GoToTop />
      <HeroImage source={content.data} />
      <div className="managementcommitte__container">
        <div className="contents__wrapper">
          <div className="contents__wrapper__title">
            <h1>{content.data?.titleContent}</h1>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: content.data?.description,
            }}
          ></div>
        </div>
        {/* Form Corporate Governance */}
        <FormCorporateGovernance />
      </div>
    </div>
  );
}

export default CorporateGovernance;
