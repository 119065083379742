import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
// import { Helmet } from "react-helmet-async";
import "./benefit.scss";

import HeroImage from "../../components/HeroImage/HeroImage";
import Membership from "../../components/Membership/Membership";
import AlloPoints from "../../components/AlloPoints/AlloPoints";
import Couponts from "../../components/Couponts/Couponts";
import GoToTop from "../../helpers/GoToTop";
import Mpc from "../../components/Mpc/Mpc";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

// import { dataMpc } from "../../data/dataMpc";
// import { dataJenisMember } from "../../data/dataJenisMember";
// import { dataAlloPoints } from "../../data/dataAlloPoints";
// import { dataCoupons } from "../../data/dataCoupons";

function Benefit() {
  // GET API WITH CUSTOM HOOKS
  const dataMembership = useFetch("/api/benefit/all");
  const dataPoints = useFetch("/api/iconpage");
  const dataMpc = useFetch("/api/mpc/all");
  const dataCoupons = useFetch("/api/kupon");

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      dataMpc: dataMpc?.response?.English,
      dataMember: dataMembership?.response?.English,
      points: dataPoints?.response?.English,
      coupons: dataCoupons?.response?.English,
    },
    Indonesia: {
      dataMpc: dataMpc?.response?.Indonesia,
      dataMember: dataMembership?.response?.Indonesia,
      points: dataPoints?.response?.Indonesia,
      coupons: dataCoupons?.response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  if (!dataMembership?.response && dataMembership?.loading) {
    return <Loading />;
  }

  return (
    <div className="benefit">
      <Seo
        title="Benefit | Allo Bank"
        description="Tingkatkan poin kamu untuk mendapatkan hadiah menarik setiap bulannya. Makin untung menabung dan bertransaksi dengan Allobank."
        url={`www.allobank.com/benefit`}
      />

      <GoToTop />
      <HeroImage source={content.dataMember?.banner} />
      {/* MPC */}
      <Mpc content={content.dataMpc} />
      {/* Membership */}
      <Membership content={content.dataMember} />
      {/* AlloPoints */}
      <AlloPoints content={content.points} />
      {/* Couponts */}
      <Couponts content={content.coupons} />
    </div>
  );
}

export default Benefit;
