import { useSelector } from "react-redux";
import "./managementcommitte.scss";

import HeroImage from "../../components/HeroImage/HeroImage";
import GoToTop from "../../helpers/GoToTop";
// import { dataManagementCommitte } from "../../data/dataManagementCommitte";
import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";
import Seo from "../../helpers/Seo";

function ManagementCommitte() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/manajemen-komite");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  // console.log("content.data :>> ", content.data);

  return (
    <div className="managementcommitte">
      <Seo
        title="Komite Manajemen | Allobank"
        description="Kenali lebih dalam Allobank Digital Bank by MEGA Corpora"
        url={`www.allobank.com/about-us/management-committee}`}
      />
      <GoToTop />
      <HeroImage source={content.data} />
      <div className="managementcommitte__container">
        <div className="contents__wrapper">
          <div
            dangerouslySetInnerHTML={{ __html: content.data?.description }}
          ></div>
          {/* <p>{content.data.p1}</p>

          <p>
            <span>{content.data.p1span1}</span>
            <span>{content.data.p1span2}</span>
            <span>{content.data.p1span3}</span>
          </p>
          <p>{content.data.p2}</p>
          <p>
            <span>{content.data.p2span1}</span>
            <span>{content.data.p2span2}</span>
            <span>{content.data.p2span3}</span>
          </p> */}
          <div className="pdf">
            <img src="/assets/pdf.svg" alt="pdf" />
            <div className="title__image">
              <a
                href={content.data?.linkPdf}
                target={"_blank"}
                rel="noreferrer"
              >
                {content.data?.titlePdf}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagementCommitte;
