import "./loading-button.scss";

function LoadingButton() {
  return (
    <div className="wrapper__loading">
      <div className="loader"></div>
      <div className="title__loading">Loading...</div>
    </div>
  );
}

export default LoadingButton;
