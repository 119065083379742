import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import "./promo.scss";
import CardPromotion from "../../components/CardPromotion/CardPromotion";
import CardPromotionBig from "../../components/CardPromotionBig/CardPromotionBig";
import CardPromotionLit from "../../components/CardPromotionLit/CardPromotionLit";
import GoToTop from "../../helpers/GoToTop";
import Seo from "../../helpers/Seo";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";

function Promo() {
  const state = useSelector((state) => state.translateReducer.bahasa);
  const { response, loading } = useFetch("/api/promo/all");
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };
  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }
  if (!response && loading) {
    return <Loading />;
  }
  const dataFourthCard = content.data?.dataPromo
    .filter((item, index, arr) => index > 0 && index < 5)
    .sort((a, b) => b.id - a.id);
  const dataNormalCard = content.data?.dataPromo
    .filter((item, index, arr) => index > 4)
    .sort((a, b) => b.id - a.id);
  return (
    <div className="promo">
      <Seo
        title="Promo | Allo Bank"
        description="Dapatkan promo Allobank dari berbagai merchant menarik setiap bulannya!"
        url={`www.allobank.com/promo}`}
      />
      <GoToTop />
      <div className="promo__container">
        <div className="promo__title">{content.data?.title}</div>
        <div className="promo__subtitle">{content.data?.subtitle}</div>
        <motion.div
          className="featured__cards"
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 1,
          }}
          viewport={{ once: true }}
        >
          <div className="big__card">
            <CardPromotionBig Promo={content.data?.dataPromo[0]} />
          </div>
          <div className="fourt__card">
            {dataFourthCard?.map((data) => (
              <CardPromotionLit dataPromo={data} key={data.id} />
            ))}
          </div>
        </motion.div>
        <motion.div
          className="normal__cards"
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 1,
          }}
          viewport={{ once: true }}
        >
          {dataNormalCard?.map((data) => (
            <CardPromotion dataPromo={data} key={data.id} />
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default Promo;
