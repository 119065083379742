import { useSelector } from "react-redux";

import "./profiles.scss";

import HeroImage from "../../components/HeroImage/HeroImage";
import ProfilesStory from "../../components/ProfilesStory/ProfilesStory";
import Vision from "../../components/Vision/Vision";
import Milestone from "../../components/Milestone/Milestone";
import OurValues from "../../components/OurValues/OurValues";
import GlobalLeader from "../../components/GlobalLeader/GlobalLeader";
import GoToTop from "../../helpers/GoToTop";
// import { dataProfiles } from "../../data/dataProfiles";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function Profiles() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/profile");
  // console.log("response :>> ", response);

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="profiles">
      <Seo
        title="Tentang Kami | Allobank"
        description="Kenali lebih dalam Allobank Digital Bank by MEGA Corpora"
        url={`www.allobank.com/about-us/profiles}`}
      />
      <GoToTop />
      <HeroImage source={content.data} />
      <ProfilesStory data={content.data} />
      <Vision data={content.data} />
      <Milestone />
      <OurValues />
      <GlobalLeader />
    </div>
  );
}

export default Profiles;
