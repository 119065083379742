import "./cardpromotionbig.scss";
import { useNavigate } from "react-router-dom";

function CardPromotionBig(props) {
  const navigate = useNavigate();
  return (
    <div className="cardpromotionbig">
      <div className="image__wrapper">
        {props.Promo && <img src={props.Promo.image} alt="" />}
      </div>
      <div className="cardpromotion__details">
        {props.Promo && (
          <div className="expired__date">
            Expired, <span>{props.Promo.expired}</span>{" "}
          </div>
        )}
        <div
          className="cardpromotion__title"
          onClick={() => navigate(`/promo/${props.Promo.slug}`)}
        >
          {props.Promo && props.Promo.titleCard}
        </div>
        <div className="cardpromotion__desc">
          {props.Promo && props.Promo.descriptionCard}
        </div>
      </div>
    </div>
  );
}

export default CardPromotionBig;
