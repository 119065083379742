import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Axios } from "../../config/axios";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./form-corporate-governance.scss";
import "react-toastify/dist/ReactToastify.css";

import { whistleblowing } from "../../data/dataWhistleblowing";

import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../helpers/notify";
import convertBase64 from "../../helpers/convertBase64";
import LoadingButton from "../../helpers/LoadingButton";

function FormCorporateGovernance() {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {};

  if (state === "Indonesia") {
    content = whistleblowing?.Indonesia;
  } else {
    content = whistleblowing?.English;
  }

  // SUBMIT
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [report, setReport] = useState("");
  const [suspactName, setSuspactName] = useState("");
  const [suspactPosition, setSuspactPosition] = useState("");
  const [dateOfEvent, setDateOfEvent] = useState(null);
  const [placeOfEvent, setPlaceOfEvent] = useState("");
  const [cronology, setCronology] = useState("");
  const fraudRef = useRef();
  const otherFraudRef = useRef();
  // State Input Files
  const [files, setFiles] = useState([]);
  const [bodyFiles, setBodyFiles] = useState([]);
  // State Drug And Drop
  const [dragActive, setDragActive] = useState(false);
  // Set loader
  const [loader, setLoader] = useState(false);

  // Input Files
  const inputFiles = async (e) => {
    if (e.target.files.length > 3) {
      notifyError("Maksimal 3 file");
    } else {
      const fileArr = Array.from(e.target.files);
      const fileMap = fileArr.map((item) => URL.createObjectURL(item));
      let inputFiles = [];

      for (let i = 0; i < fileMap.length; i++) {
        inputFiles.push(await convertBase64(fileMap[i]));
      }
      setFiles(e.target.files);
      setBodyFiles(inputFiles);
    }
  };

  // Handle Drug And Drop
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else {
      setDragActive(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (e.dataTransfer.files.length > 3) {
        notifyError("Maksimal 3 file");
      } else {
        setFiles(e.dataTransfer.files);

        const fileArr = Array.from(e.dataTransfer.files);
        const fileMap = fileArr.map((item) => URL.createObjectURL(item));
        let inputFiles = [];

        for (let i = 0; i < fileMap.length; i++) {
          inputFiles.push(await convertBase64(fileMap[i]));
        }
        setBodyFiles(inputFiles);
      }
    }
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length > 7 && email.includes("@")) {
      if (report.length > 0) {
        if (suspactName.length > 5) {
          if (suspactPosition.length > 5 && suspactPosition.length < 50) {
            if (dateOfEvent !== null) {
              if (placeOfEvent.length > 0) {
                if (cronology.length > 0) {
                  postData();
                } else {
                  notifyWarning("Kronologi harus diisi");
                }
              } else {
                notifyWarning("Tempat Kejadian harus diisi");
              }
            } else {
              notifyWarning("Tanggal Kejadian harus diisi");
            }
          } else {
            notifyWarning("Jabatan Terlapor harus diisi");
          }
        } else {
          notifyWarning("Minimal panjang nama 5 karakter");
        }
      } else {
        notifyWarning("Pilih pelanggaran yang dilaporkan");
      }
    } else {
      notifyWarning("Email harus yang valid");
    }
  };

  const postData = async () => {
    const data = {
      name: name,
      email: email,
      phone_number: phone,
      report: report,
      suspect_name: suspactName,
      suspect_position: suspactPosition,
      approximate_date: moment(dateOfEvent).format("d MMMM yyyy"),
      place_of_event: placeOfEvent,
      cronology: cronology,
      attachment: bodyFiles,
    };

    try {
      setLoader(true);
      const response = await Axios.post("/api/whistleblowing/report/v2", data);
      if (response.status === 200) {
        notifySuccess(
          `${response.data.msg}, Formulir berhasil dikirim, kami akan segera menindak lanjuti laporan Anda`
        );
        setName("");
        setEmail("");
        setPhone("");
        setReport("");
        setSuspactName("");
        setSuspactPosition("");
        setDateOfEvent(null);
        setPlaceOfEvent("");
        setCronology("");
        // Clear Input File
        setFiles([]);
        setDragActive(false);
        // Clear input radio
        fraudRef.current.checked = false;
        otherFraudRef.current.checked = false;
        setLoader(false);
      }
    } catch (error) {
      notifyError("Ada kesalahan pada server. Data gagal dikirim");
    }
  };

  return (
    <motion.div
      className="corporate-governance"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <div className="message">
        <div className="batik">
          <img src="/assets/batikCard.svg" alt="" />
        </div>
        <div className="title-container">
          <div className="title">{content.title}</div>
          <div className="group__label">
            <div
              className="label"
              dangerouslySetInnerHTML={{ __html: content.required }}
            ></div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {/* Personal Information */}
          <div className="group__label">
            <div
              className="label"
              dangerouslySetInnerHTML={{ __html: content.personalInformation }}
            ></div>
          </div>
          <div className="group__control">
            <input
              type="text"
              placeholder={content.name}
              onChange={(e) => setName(e.target.value)}
              value={name}
              className={
                name.length === 0
                  ? "netral"
                  : name.length > 2
                  ? "success"
                  : "error"
              }
            />
            <input
              type="text"
              placeholder={content.phone}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              className={
                phone.length === 0
                  ? "netral"
                  : phone.length > 10 &&
                    phone.length < 15 &&
                    isNaN(phone) === false
                  ? "success"
                  : "error"
              }
            />
          </div>
          <div className="group__control">
            <input
              type="text"
              placeholder={content.email}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className={
                email.length === 0
                  ? "netral"
                  : email.length > 7 &&
                    email.includes("@") &&
                    email.includes(".")
                  ? "success"
                  : "error"
              }
            />
          </div>
          {/* Report Of Violation */}
          <div className="group__label">
            <div className="label">{content.reportViolation?.title}</div>
          </div>
          <div className="group__radio">
            <p>{content.reportViolation?.description}</p>
            <div className="radio">
              <input
                ref={fraudRef}
                type="radio"
                id="fraud"
                name="reportViolation"
                value={content.reportViolation?.option1}
                onChange={(e) => setReport(e.target.value)}
              />
              <label htmlFor="fraud">{content.reportViolation?.option1}</label>
            </div>
            <div className="radio">
              <input
                ref={otherFraudRef}
                type="radio"
                id="other_fraud"
                name="reportViolation"
                value={content.reportViolation?.option2}
                onChange={(e) => setReport(e.target.value)}
              />
              <label htmlFor="outher_fraud">
                {content.reportViolation?.option2}
              </label>
            </div>
          </div>
          {/* Suspect Information */}
          <div className="group__label">
            <div
              className="label"
              dangerouslySetInnerHTML={{ __html: content.suspectInformation }}
            ></div>
          </div>
          <div className="group__control">
            <input
              type="text"
              placeholder={content.suspectName}
              onChange={(e) => setSuspactName(e.target.value)}
              value={suspactName}
              className={
                suspactName.length === 0
                  ? "netral"
                  : suspactName.length > 5
                  ? "success"
                  : "error"
              }
            />
            <input
              type="text"
              placeholder={content.suspectPosition}
              onChange={(e) => setSuspactPosition(e.target.value)}
              value={suspactPosition}
              className={
                suspactPosition.length === 0
                  ? "netral"
                  : suspactPosition.length > 10
                  ? "success"
                  : "error"
              }
            />
          </div>
          <div className="group__control">
            <DatePicker
              selected={dateOfEvent ? dateOfEvent : null}
              onChange={(date) => setDateOfEvent(date)}
              dateFormat="MMMM d, yyyy"
              placeholderText={content.approximateDate}
              maxDate={moment().toDate()}
              className={
                dateOfEvent === null
                  ? "date-picker-wbs"
                  : dateOfEvent
                  ? "success-wbs"
                  : "error-wbs"
              }
            />
            <input
              type="text"
              placeholder={content.placeOfEvent}
              onChange={(e) => setPlaceOfEvent(e.target.value)}
              value={placeOfEvent}
              className={
                placeOfEvent.length === 0
                  ? "netral"
                  : placeOfEvent.length > 3
                  ? "success"
                  : "error"
              }
            />
          </div>
          <div className="group__control">
            <textarea
              name=""
              id=""
              placeholder={content.cronology}
              onChange={(e) => setCronology(e.target.value)}
              value={cronology}
              className={
                cronology.length === 0
                  ? "netral"
                  : cronology.length > 25
                  ? "success"
                  : "error"
              }
            ></textarea>
          </div>
          {/* File Attachment */}
          <div className="group__label">
            <div
              className="label"
              dangerouslySetInnerHTML={{ __html: content.attachFile }}
            ></div>
          </div>
          <div
            className="group__control"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            {files.length > 0 || dragActive ? (
              <div className="wrapper__multi column">
                <div className="text__wrapper file__length">
                  <div className="text__file">{files.length} file</div>
                </div>
                {Array.from(files).map((file, idx) => (
                  <div className="text__wrapper" key={idx}>
                    <div className="text__file">{file.name}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="wrapper__multi">
                <input
                  id="input-file"
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, .pdf"
                  name="files"
                  multiple
                  className="multi__file"
                  onChange={inputFiles}
                />
                <div className="img__wrapper">
                  <label htmlFor="input-file" className="input__file">
                    <img src="/assets/icon-file.svg" alt="icon-file" />
                    <div className="text__file">
                      {content.inputFile.pilihFile}
                    </div>
                  </label>
                </div>
                <div className="text__wrapper">
                  <div className="text__file">{content.inputFile.seret}</div>
                </div>
              </div>
            )}
          </div>
          <div className="btn__wrapper">
            <button type="submit" className="btn__contact">
              {loader ? <LoadingButton /> : content.buttonSubmit}
            </button>
          </div>
        </form>
      </div>
      {/* Notify */}
      <ToastContainer />
    </motion.div>
  );
}

export default FormCorporateGovernance;
