import "./tables.scss";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

function Tables({ dataTables }) {
  // Ikon Dinamis
  const setIcon = (content) => {
    if (content === "ok") {
      return <img src="/assets/yes.svg" alt="logo" />;
    }

    if (content === "no") {
      return <img src="/assets/no.svg" alt="logo" />;
    }

    return content;
  };

  // Add Class Background
  const setBackground = (id) => {
    let background;

    if (id % 2 === 0) {
      background = "backGray";
    } else {
      background = "backWhite";
    }
    return background;
  };

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Allo Pay and Allo Pay+",
      plans: "Plans",
      title2: "Allo Grow",
      tiering: "Tiering No",
      tenure: "Tenure (Days)",
      holding: "Holding Period (Days)",
      interest: "Interest Rate",
      date_update: "*Updated on 19 September 2023",
    },
    Indonesia: {
      title: "Allo Pay dan Allo Pay+",
      plans: "Fitur",
      title2: "Allo Grow",
      tiering: "Tiering No",
      tenure: "Jangka Waktu (Hari)",
      holding: "Periode Penyimpanan (Hari)",
      interest: "Suku Bunga",
      date_update: "*Diperbarui pada 19 September 2023",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <>
      {dataTables && (
        <motion.div
          className="products__tabs__tables"
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 1,
          }}
          viewport={{ once: true }}
        >
          {Object.keys(dataTables[0]).length === 4 ? (
            <>
              {" "}
              <div className="products__tabs__tables__title">
                {content.title}
              </div>
              <div className="products__tabs__tables__table">
                <div className="headings">
                  <div className="headings__plans">{content.plans}</div>
                  <div className="headings__allopay">Allo Pay</div>
                  <div className="headings__allopayplus">Allo Pay+</div>
                </div>
                {dataTables?.map((item) => (
                  <div
                    className={`bodys ${setBackground(item.id)}`}
                    key={item.id}
                  >
                    <div className="bodys__plans">{item.plans}</div>
                    <div className="bodys__allopay">
                      {setIcon(item.alloPay)}
                    </div>
                    <div className="bodys__allopayplus">
                      {setIcon(item.alloPlus)}
                    </div>
                  </div>
                ))}
              </div>{" "}
            </>
          ) : Object.keys(dataTables[0]).length === 5 ? (
            <>
              {" "}
              <div className="products__tabs__tables__title">
                {content.title2}
              </div>
              <div className="products__tabs__tables__table">
                <div className="headings">
                  <div className="headings__allopay">{content.tiering}</div>
                  <div className="headings__allopay">{content.tenure}</div>
                  <div className="headings__allopay">{content.holding}</div>
                  <div className="headings__allopayplus">
                    {content.interest}
                  </div>
                </div>
                {dataTables?.map((item) => (
                  <div
                    className={`bodys ${setBackground(item.id)}`}
                    key={item.id}
                  >
                    <div className="bodys__allopay">{item.tiering_no}</div>
                    <div className="bodys__allopay">{item.tenure}</div>
                    <div className="bodys__allopay">{item.holding_period}</div>
                    <div className="bodys__allopayplus">
                      {item.interest_rate}
                    </div>
                  </div>
                ))}
              </div>
              <div className="dateUpdate">{content.date_update}</div>
            </>
          ) : (
            ""
          )}
        </motion.div>
      )}
    </>
  );
}

export default Tables;
