import React from "react";
import { Helmet } from "react-helmet-async";

const Seo = (props) => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content={props.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta
          property="og:image"
          itemprop="image"
          content="https://hanoman.allobank.com/container/media/assets/home/banner/web/Kii2Am_desktop-homepage banner-eng.png"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={props.url} />
        <meta property="twitter:url" content={props.url} />
        <meta name="twitter:title" content={props.title} />
        <meta name="twitter:description" content={props.description} />
        <meta
          name="twitter:image"
          content="https://hanoman.allobank.com/container/media/assets/home/banner/web/Kii2Am_desktop-homepage banner-eng.png"
        />
      </Helmet>
    </>
  );
};

export default Seo;
