export const whistleblowing = {
  English: {
    title: "Allo Bank Whistleblowing System e-Form",
    required: "<i style='color:red'>*Required</i>",
    buttonSubmit: "Submit",
    name: "Name (Optional)",
    email: "Email *",
    message: "Message",
    phone: "Phone Number (Optional)",
    suspectName: "Suspect Name *",
    suspectPosition: "Suspect Position *",
    approximateDate: "Approximate Date of Event *",
    placeOfEvent: "Place of Event *",
    cronology: "Chronology *",
    personalInformation: "Personal Information",
    attachFile: "File Attachment",
    suspectInformation: "Suspect Information",
    reportViolation: {
      title: "Report of Violation",
      description: "What do you want to report? *",
      option1: "Fraud",
      option2: "Violation of Law, Norms, and Ethics (Code of Conduct)",
    },
    inputFile: {
      pilihFile: "Choose file(s)",
      seret: "or drag the file(s) here",
    },
  },
  Indonesia: {
    title: "Formulir Pelaporan Whistleblowing System Allo Bank",
    required: "<i style='color:red'>*Wajib</i>",
    buttonSubmit: "Kirim",
    name: "Nama (Opsional)",
    email: "Email *",
    message: "Pesan",
    phone: "Nomor Telepon (Opsional)",
    suspectName: "Name Terlapor *",
    suspectPosition: "Jabatan Terlapor *",
    approximateDate: "Perkiraan Waktu Kejadian *",
    placeOfEvent: "Tempat Kejadian *",
    cronology: "Kronologi *",
    personalInformation: "Informasi Pribadi",
    attachFile: "Lampiran",
    suspectInformation: "Informasi Terlapor",
    reportViolation: {
      title: "Pelanggaran yang dilaporkan",
      description: "Apa yang ingin Anda laporkan *",
      option1: "Fraud",
      option2: "Pelanggaran Hukum, norma dan etika (kode etik)",
    },
    inputFile: {
      pilihFile: "Pilih File",
      seret: "atau seret file kesini",
    },
  },
};
